import { dataguard } from '@/renderer/utils/vike/dataguard';

export async function data(pageContext: Vike.PageContext) {
  return await dataguard(pageContext, {
    ignoreLoggedOut: true,
    redirectTo: (data) => {
      if (data?.user) {
        const url = pageContext.urlPathname || pageContext.urlParsed.pathname;
        const redirUrl =
          pageContext.urlParsed?.search?.redirect?.[0] === '/' &&
          pageContext.urlParsed.search.redirect;

        if (redirUrl && url !== redirUrl) {
          return [redirUrl];
        }

        if (!url?.startsWith('/release-center')) {
          return ['/release-center'];
        }
      }
    },
  });
}
