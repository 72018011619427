import { twMerge } from 'tailwind-merge';

import useClientInteractive from '@/renderer/utils/useInteractive';

export default function FormSubmitButton({
  disabled,
  loading,
  inline,
  children,
  className,
  stretch,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string;
  disabled?: boolean;
  inline?: boolean;
  loading?: boolean;
  onClick?: React.ComponentPropsWithoutRef<'button'>['onClick'];
  children: React.ReactNode;
  stretch?: boolean;
}) {
  const interactive = useClientInteractive();

  return (
    <button
      type="submit"
      className={twMerge(
        inline ? 'inline-flex' : 'flex',
        stretch ? 'w-full' : '',
        'cursor-pointer justify-center rounded border-0 bg-zinc-800 px-3 py-2.5 text-base font-semibold leading-6 text-white shadow-sm hover:bg-zinc-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 disabled:cursor-not-allowed disabled:opacity-50 data-[loading=true]:animate-pulse data-[loading=true]:cursor-progress sm:text-sm dark:bg-emerald-600 hover:dark:bg-emerald-500',
        className,
      )}
      disabled={disabled || !interactive}
      data-loading={loading}
      {...rest}
    >
      {interactive ? children : 'Please wait...'}
    </button>
  );
}
