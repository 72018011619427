// Imports
import { ArrowRightIcon } from '@heroicons/react/20/solid';
import { useEffect, useMemo, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { navigate, reload } from 'vike/client/router';

import type { WithFormErrors } from '@/components/Forms/FormControl';
import FormControl from '@/components/Forms/FormControl';
import FormErrors from '@/components/Forms/FormErrors';
import FormSubmitButton from '@/components/Forms/FormSubmitButton';
import PasswordInput from '@/components/Forms/PasswordInput';
import useFormSubmit from '@/components/Forms/useFormSubmit';
import { Logo } from '@/components/Logo';
import Redirect from '@/components/Redirect';
import { Button } from '@/components/UI/Button';
import Footer from '@/renderer/Footer';
import { useIsClient } from '@/renderer/utils/hooks/useIsClient';
import useMe, { authToken } from '@/renderer/utils/useMe';
import { usePageContext } from '@/renderer/utils/usePageContext';
import useRequest from '@/renderer/utils/useRequest';
import type { RequestSchema } from '@/types/request';

// export const title = ({ urlOriginal }: Vike.PageContext) => urlOriginal === '/signin' ? 'Login' : 'Please sign in to access this page';

// Types
type LoginData = { username: string; password: string };

// API requests
const authLoginRequest: RequestSchema<API.LoginState, { username: string; password: string }> = {
  name: 'authLoginRequest',
  input: '/api/v1/auth/login',
  method: 'POST',
};

export function LoginForm({
  guarded = false,
  subtitle,
  hideLogo = false,
}: {
  guarded: boolean;
  subtitle?: (React.ReactNode | JSX.Element)[] | React.ReactNode | JSX.Element | string;
  hideLogo?: boolean;
}) {
  // Form hooks
  const form = useForm<WithFormErrors<LoginData>>();
  const {
    formState: { isSubmitSuccessful },
  } = form;

  const { abortReason, urlParsed, urlOriginal } = usePageContext();
  const [me, setMe] = useMe(true);
  const redirectTimeout = useRef<NodeJS.Timeout>();
  const IS_CLIENT = useIsClient();

  // Request hooks
  const request = useRequest(authLoginRequest, { manual: true, hideAlert: true });

  const redirUrl = urlParsed?.search?.redirect?.[0] === '/' && urlParsed.search.redirect;

  // Handlers
  const handleFormSubmit = useFormSubmit(
    {
      form: form,
      callback: async (body) => {
        const result = await request.run(body, { throwOnRun: true });

        if (result?.message === 'API.AUTHENTICATION.LOGIN.SUCCESS') {
          if (result.user) {
            setMe(result.user);
          }

          if (result.session?.token) {
            authToken(result.session.token);
          }

          if (redirUrl) {
            navigate(redirUrl);
          } else if (urlOriginal === '/signin') {
            navigate('/release-center');
          } else {
            if (IS_CLIENT) {
              // Just in case we get bugged and the page never changes, force a refresh of the current URL
              redirectTimeout.current = setTimeout(() => window.location.reload(), 10000);
            }

            reload();
          }
        }
      },
    },
    [redirUrl],
  );

  useEffect(() => {
    return () => {
      if (redirectTimeout.current) {
        clearTimeout(redirectTimeout.current);
      }
    };
  }, []);

  const registerUrl = useMemo(() => {
    if (urlParsed.search.redirect && urlParsed.search.redirect[0] === '/') {
      return `/register?redirect_uri=${encodeURIComponent(urlParsed.search.redirect)}`;
    } else if (urlOriginal !== '/signin') {
      return `/register?redirect_uri=${encodeURIComponent(urlOriginal)}`;
    }

    return '/register';
  }, [urlParsed.search.redirect, urlOriginal]);

  if (me) {
    return <Redirect url={redirUrl || '/release-center'} sideEffects={false} />;
  }

  // Render
  return (
    <>
      <div className="flex min-h-full items-center justify-center bg-[Canvas] px-4 py-12 sm:px-6 lg:px-8">
        <div className="w-full max-w-sm space-y-5">
          {!hideLogo && (
            <a href="/">
              <Logo className="mx-auto size-16 select-none" />
            </a>
          )}
          {!!abortReason && (
            <h2 className="text-center text-xl font-normal">{abortReason as string}</h2>
          )}
          {!!guarded && !subtitle && (
            <h2 className="text-center text-xl font-normal">Please sign in to access this page.</h2>
          )}
          {!!subtitle && <h3 className="text-center text-xl font-normal">{subtitle}</h3>}
          <form onSubmit={handleFormSubmit} method="POST" className="space-y-6">
            <FormControl
              form={form}
              name="username"
              label="Email or username"
              autoComplete="email"
              required
              autoFocus
              autoCapitalize="off"
              autoCorrect="off"
              autoSave="off"
            />

            <FormControl
              form={form}
              name="password"
              label="Password"
              aria-autocomplete="list"
              autoComplete="password"
              wrap={PasswordInput}
              required
              minLength={8}
              forgotPassword
              autoCapitalize="off"
              autoCorrect="off"
              autoSave="off"
            />

            <FormErrors
              form={form}
              request={request}
              rewrite={(err) => {
                if (err.code === 404) {
                  return (
                    <span className="mt-1 text-xs">
                      Incorrect email/username or password.{' '}
                      <a href="/forgot-password">Reset password?</a>
                    </span>
                  );
                }

                return err.toString();
              }}
            />

            <FormSubmitButton
              disabled={request.loading}
              loading={request.loading || isSubmitSuccessful}
              className="w-full"
            >
              {(isSubmitSuccessful && 'Successfully logged in, please wait...') || 'Sign in'}
            </FormSubmitButton>

            <div className="text-center">
              <a href="/forgot-password" className="text-xs text-zinc-600 dark:text-zinc-400">
                Forgot password?
              </a>
            </div>

            <div className="flex items-center justify-center space-x-2 self-center text-zinc-700 dark:text-zinc-300">
              <span className="text-sm">Not a member?</span>
              <a
                href={registerUrl}
                className="flex flex-row items-center space-x-1 text-sm font-semibold"
              >
                <span>Create an account </span>
                <ArrowRightIcon height={16} className="size-4" />
              </a>
            </div>

            {!!guarded && (
              <div className="flex items-center justify-center space-x-2 self-center">
                <Button variant="ghost" onClick={() => history.back()}>
                  Or return to previous page <ArrowRightIcon height={16} className="size-4" />
                </Button>
              </div>
            )}
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
