import { useCallback } from 'react';
import type { FieldValues, Path, UseFormReturn } from 'react-hook-form';

import { RequestError } from '@/renderer/utils/errors';

import type { WithFormErrors } from './FormControl';

type WrapProps<F extends FieldValues> = {
  form: UseFormReturn<F>;
  callback: (body: F, e?: React.BaseSyntheticEvent) => void | Promise<void>;
};

export default function useFormSubmit<V extends WithFormErrors<FieldValues>>(
  { form, callback }: WrapProps<V>,
  deps?: unknown[],
) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callbackCached = useCallback(callback, deps || []);

  const onGo = useCallback(
    async (body: V, e?: React.BaseSyntheticEvent) => {
      try {
        form.clearErrors();

        await callbackCached(body, e);
      } catch (error) {
        if (error instanceof RequestError) {
          const bod = error.data?.details?.body || error.data?.details?.query;
          const path = bod?.path?.join?.('.') as keyof V;

          if (path && typeof body[path] !== 'undefined') {
            // If this path exists on body, consider it a visible input and assign the error accordingly
            form.setError(path as Path<V>, {
              type: 'server',
              message: error.data.details.body.message || error.message,
            });
          } else if (
            error.data?.columns?.[0]?.length &&
            typeof body[error.data.columns[0] as keyof V] !== 'undefined'
          ) {
            // If this column exists on body, consider it a visible input and assign the error accordingly
            form.setError(error.data.columns[0], { type: 'server', message: error.message });
          } else {
            if (bod?.[0]) {
              // Array of validation errors
              for (const details of error.data.details.body) {
                const epath = details.path?.join?.('.') as keyof V;

                if (epath && typeof body[epath] !== 'undefined') {
                  form.setError(epath as Path<V>, {
                    type: 'server',
                    message: details.message || error.message,
                  });
                }
              }
            }
          }
        }

        // All other errors go to the bottom
        form.setError('formErrors' as Path<V>, {
          type: 'server',
          message: (error instanceof Error ? error.message : String(error)) || 'Unknown error',
        });
      }
    },
    [form, callbackCached],
  );

  const onError = useCallback(() => {
    form.clearErrors();
    form.handleSubmit(onGo)();
  }, [form, onGo]);

  return form.handleSubmit(onGo, onError);
}
