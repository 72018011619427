import { useEffect, useState } from 'react';

/** Primarily to prevent HTTP form submission. */
export default function useClientInteractive() {
  const [clientSideInteractive, setClientSideInteractive] = useState(false);

  useEffect(() => setClientSideInteractive(true), []);

  return clientSideInteractive;
}
