import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import type { DetailedHTMLProps, InputHTMLAttributes, Ref } from 'react';
import { forwardRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

function PasswordInput(
  {
    error,
    className,
    ...props
  }: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    error?: boolean;
    className?: string;
  },
  ref: Ref<HTMLInputElement>,
) {
  const [visiblePassword, setVisiblePassword] = useState(false);
  const Icon = visiblePassword ? EyeIcon : EyeSlashIcon;

  return (
    <div className="relative">
      <input
        {...props}
        ref={ref}
        className={twMerge(
          'block h-auto w-full rounded border-0 py-1.5 pl-3 pr-8 text-base leading-6 text-zinc-900 shadow-sm ring-1 ring-inset ring-zinc-300 placeholder:text-zinc-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-emerald-500 focus-visible:outline-none dark:bg-white/5 dark:text-zinc-200 dark:ring-white/10 dark:focus:ring-emerald-400 sm:text-sm',
          !!error && 'ring-red-500',
          className,
        )}
        type={visiblePassword ? 'text' : 'password'}
      />
      <div
        className="absolute right-2 top-2 cursor-pointer text-zinc-400 hover:text-zinc-500"
        onClick={() => setVisiblePassword(!visiblePassword)}
      >
        <Icon height={20} className="h-5 w-auto" />
      </div>
    </div>
  );
}

export default forwardRef(PasswordInput);
