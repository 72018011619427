import { LoginForm } from './LoginForm';

// Main component
export default function LoginPage({
  subtitle,
}: {
  subtitle?: (React.ReactNode | JSX.Element)[] | React.ReactNode | JSX.Element | string;
}) {
  return <LoginForm guarded={false} subtitle={subtitle} />;
}
