import type { ReactNode } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

import type { RequestError } from '@/renderer/utils/errors';
import { ValidationError } from '@/renderer/utils/errors';
import type { UseRequestResult } from '@/renderer/utils/useRequest';

export default FormErrors;

type WrapProps<
  F extends FieldValues,
  R extends FieldValues = F,
  IgnoreMismatch extends boolean = false,
> = {
  form: UseFormReturn<F>;
  ignoreMismatch?: IgnoreMismatch;
  // @TODO biome-ignore lint/suspicious/noExplicitAny:
  request?: UseRequestResult<any, IgnoreMismatch extends true ? R : F>;
  rewrite?: (err: RequestError) => ReactNode;
};

function FormErrors<
  F extends FieldValues = FieldValues,
  R extends FieldValues = F,
  IgnoreMismatch extends boolean = false,
>({ form, request, rewrite }: WrapProps<F, R, IgnoreMismatch>) {
  const fieldKeys = form.control._fields ? Object.keys(form.control._fields) : null;
  const errors = form.formState.errors;

  return (
    <>
      {/* Misc errors from the formState that aren't tied specifically to a field */}
      {Object.keys(errors).map((key) => {
        if (!fieldKeys || !fieldKeys.includes(key)) {
          /* Only show a generic request error if it isn't the same as the formState Error */
          // @TODO biome-ignore lint/suspicious/noExplicitAny:
          if ((request?.error as any)?.message !== errors[key as keyof F]?.message) {
            return (
              <div key={key}>
                <label>Error: {errors[key as keyof F]?.message?.toString()}</label>
              </div>
            );
          }
        }
      })}

      {!!request?.error && (
        <div className="space-x-2 text-red-500">
          {/* @TODO biome-ignore lint/suspicious/noExplicitAny: */}
          <label className="text-red-400">
            {rewrite ? rewrite(request.error as any) : request.error.toString()}
          </label>

          {/* Any additional server validation errors */}
          {request.error instanceof ValidationError &&
            (request.error.data?.details?.body || request.error.data?.details?.query)?.map?.(
              ({ message, path }) => {
                const joined = path?.join('.');

                if (!fieldKeys || !fieldKeys.includes(joined)) {
                  return <label key={joined}>{message}</label>;
                }
              },
            )}
        </div>
      )}
    </>
  );
}
