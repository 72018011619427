import { useEffect } from 'react';
import { navigate } from 'vike/client/router';

import { IS_SERVER } from '@/renderer/utils/ssr';

export default function Redirect({
  sideEffects = true,
  text,
  url,
}: {
  sideEffects?: boolean;
  text?: string;
  url: string;
}) {
  let toUrl = url;

  if (sideEffects) {
    if (!IS_SERVER && location?.search?.includes('via=') && !url.includes('via=')) {
      const via = location.search.match(/[?&]via=([A-Za-z0-9_.-]+)/)?.[1];

      if (via) {
        toUrl += (toUrl.includes('?') ? '&' : '?') + 'via=' + via;
      }
    }
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies:
  useEffect(() => {
    navigate(toUrl, { overwriteLastHistoryEntry: true });
  }, []);

  return text ? <a href={toUrl}>{text}</a> : null;
}
